import React, { useState, useEffect } from "react";
// import "../styles/teammember.scss";
// import "../styles/loader.css";
import { useParams } from "react-router-dom";
import Map from "../components/Map";
import shareIcon from "../assets/img/share-icon.png";
import plusIcon from "../assets/img/plus-icon.png";
function MemberDetail() {
    let params = useParams();
    let userId = params.userId;
    const [mem, setMem] = useState([]);
    const [Loaded, setLoaded] = useState(false);
    // const user = useContext(UserContext);
    useEffect(() => {
        fetch('https://melcorpstrata.com.au/wp-json/wp/v2/team-member/'+userId)
        .then(res => res.json())
        .then((res) => {
            setMem(res);
            setLoaded(true);
            console.log(res);
        });
    }, [userId]);
    if(!Loaded) {
        return (
            <div className='container loading-container'>
                <div className='loader'>
                <div className='loader--dot'></div>
                <div className='loader--dot'></div>
                <div className='loader--dot'></div>
                <div className='loader--dot'></div>
                <div className='loader--dot'></div>
                <div className='loader--dot'></div>
                <div className='loader--text'></div>
                </div>
            </div>
        )
    }
    let memberFields = mem['toolset-meta']['team-members-group'];
    const shareContactHref = "mailto:?subject=Melcorp%20Strata%20-%20"+memberFields['team-member-full-name']['raw']+"'s%20contact%20&body=Sharing contact page: "+window.location.href;
    const copy = (e) => {
        e.preventDefault();
        const el = document.createElement('input');
        el.value = window.location.href;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        // Show copied message
        let existingCopied = document.getElementById('copiedID');
        if(existingCopied) {
            existingCopied.remove();
        }
        const copied = document.createElement('p');
        copied.className = 'copied';
        copied.id = 'copiedID';
        copied.innerHTML = 'URL copied to clipboard!';
        document.getElementById('share-wrapper').appendChild(copied);
    }
    return (
        <>
            <div className="container">
                <div className="team-member-detail-wrapper">               
                    <img className="photo" src={memberFields['team-member-listing-photo']['raw']} alt="user profile" />
                    <div className="info-wrap">
                        <h1 className="name">{memberFields['team-member-full-name']['raw']}</h1>
                        <p className="title">{memberFields['job-title']['raw']}</p>
                        <p>{memberFields['team-member-phone']['raw']}</p>
                        <p>{memberFields['email']['raw']}</p>
                    </div>
                    <div className="big-wrap">
                        <div className="cta-wrap">
                            <a href={"tel:"+memberFields['team-member-phone']['raw']}>Call</a>
                            <a href={"mailto:"+memberFields['email']['raw']}>Email</a>
                        </div>
                        <div className="cta-wrap-2" id="share-wrapper">
                            <a className="clear-button" href={'https://melcorpstrata.com.au/wp-json/businesscards/v1/vcard/'+userId}>Add to contact <img src={plusIcon} /></a>
                            <a className="clear-button" href="" onClick={copy}>Share contact <img src={shareIcon} /></a>
                        </div>
                    </div>
                </div>
            </div>            
            <Map />
            <a className="back-button" href="/"><span>&lt;</span> <p>Our Team</p></a>
        </>
    )
}
export default MemberDetail;
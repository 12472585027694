import React from "react";
function TeamMember(props) {
    const memberCustomFields = props.profile["toolset-meta"]["team-members-group"];
    const fullName = memberCustomFields["team-member-full-name"]["raw"];
    const jobTitle = memberCustomFields["job-title"]["raw"];
    const photo = memberCustomFields["team-member-listing-photo"]["raw"];
    const memberID = props.profile.id;
    let linkURL = window.location.href+"member-detail/"+memberID;
    let qrcodeURL = "https://api.qrserver.com/v1/create-qr-code/?data="+window.location.href+"member-detail/"+memberID+"&color=black&bgcolor=247-244-234";
    return(        
        <div className="aMember">
            <a href={linkURL} ><img className="aMember__photo" src={photo} alt="user profile" /></a>
            <div>
                <a href={linkURL}><h2 className="aMember__name">{fullName}</h2></a>
                <p className="aMember__title">{jobTitle}</p>
            </div>
            <a className="qrcode-link" href={linkURL}><img alt="profile qr code" src={qrcodeURL} /></a>
        </div>  
    );
}
export default TeamMember;
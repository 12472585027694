import React from "react";

function MyFooter() {
    let date = new Date();
    return (
        <>
        <div className="footer">   
            <a href="mailto:admin@melcorpstrata.com.au"><img src="https://melcorpstrata.com.au/wp-content/themes/strata2022/dist/images/Email.png" /></a>
            <a rel="nofollow" href="https://au.linkedin.com/company/melcorpstrata" target="_blank"><img src="https://melcorpstrata.com.au/wp-content/themes/strata2022/dist/images/LinkedIn.png" /></a>
            <a rel="nofollow" href="https://www.vic.strata.community/" target="_blank"><img src="https://melcorpstrata.com.au/wp-content/themes/strata2022/dist/images/SA_logo.png" /></a>
            
        </div>
        <div className="footer-links">
            <ul>
                <li>© {date.getFullYear()} Melcorp Pty Ltd</li>
                <li><a href="https://melcorpstrata.com.au/terms-of-use" target="_blank">Terms &amp; Conditions</a></li>
                <li><a href="https://melcorpstrata.com.au/privacy-policy" target="_blank">Privacy Policy</a></li>
            </ul>
        </div>
        </>
    )
}

export default MyFooter;
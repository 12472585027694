import React, { useState, useEffect, useRef } from "react";
import TeamMember from "../components/TeamMember";
// import "../styles/ebusinesscards.scss";
// import "../styles/loader.css";

function Homepage() {
    const [profiles, setProfiles] = useState([]);
    const [DataisLoaded, setLoaded] = useState(false);
    const fullList = useRef([]);

    const sortJSON = (data) => {
        return data.sort(function(a, b) {
            if(a["toolset-meta"]["team-members-group"]["display-ranking"]["raw"] > b["toolset-meta"]["team-members-group"]["display-ranking"]["raw"]) {
                return -1;
            }
            if(a["toolset-meta"]["team-members-group"]["display-ranking"]["raw"] < b["toolset-meta"]["team-members-group"]["display-ranking"]["raw"]) {
                return 1;
            }
            return 0;
        });
    };
    useEffect(() => {
        fetch('https://melcorpstrata.com.au/wp-json/wp/v2/team-member?per_page=30')
        .then(res => res.json())
        .then((json) => {
            json = sortJSON(json);
            const activeMember = json.filter((data) => { return data.status.includes('publish')});       
            setProfiles(activeMember);            
            setLoaded(true);
            fullList.current = activeMember;
        });
    }, []);

    const handleSearch = (query) => {
        if (query !== '') {           
            let filteredItems = fullList.current.filter((data) => {
                return data["toolset-meta"]["team-members-group"]["team-member-full-name"]["raw"].toLowerCase().includes(query.toLowerCase());
            });
            
            setProfiles(filteredItems);
        } else {
            setProfiles(fullList.current);
        }
    }
    const clearField = () => {
        document.getElementById("search-input").value = "";
        // document.getElementById("search-input").focus();
        handleSearch("");
    }

    if(!DataisLoaded) 
        return <div className='container loading-container'>
            <div className='loader'>
            <div className='loader--dot'></div>
            <div className='loader--dot'></div>
            <div className='loader--dot'></div>
            <div className='loader--dot'></div>
            <div className='loader--dot'></div>
            <div className='loader--dot'></div>
            <div className='loader--text'></div>
            </div>
        </div>;
    
    return (
        <div className="ebusinesscard-page">
            <div className="container">
                <div className="search-bar">
                    <input type="text" placeholder="Search member..." id="search-input" onChange={e => handleSearch(e.target.value)} />
                    <button onClick={clearField}>Reset</button>
                </div>
                {profiles.map((item, key) => (
                    <TeamMember profile={item} key={key} />
                ))}
            </div>            
        </div>
    )
}
export default Homepage;